const { Child } = require('@salesforce-mc/crossdocmessenger');
const { getDocType } = require('@salesforce-mc/messageutils');
const { sanitizeHtml } = require('@salesforce-mc/securehtmlutils');
const Promise = require('es6-promise').Promise;

const SecurePreview = function () {
	this.iframe = document.getElementById('preview').contentDocument;
};

SecurePreview.prototype = {
	constructor: SecurePreview,
	ignoreClicks: function (e) {
		let href = e.target.hasAttribute('href');
		if (href) { window.open(e.target.href, '_blank'); }
		e.preventDefault();
	},

	refresh: function () {
		const promise = new Promise(function (resolve) {
			const previewFrame = document.createElement('iframe');
			previewFrame.id = 'preview';
			document.getElementById('preview').remove();
			document.body.appendChild(previewFrame);
			this.iframe = document.getElementById('preview').contentDocument;
			resolve();
		}.bind(this));

		return promise;
	},

	handleClicks (event) {
		let hasHref = event.target.hasAttribute('href') || event.target.parentElement.hasAttribute('href');
		let href;

		if (hasHref) {
			href = event.target.href || event.target.parentElement.href;

			window.open(href, '_blank');

			event.preventDefault();
		}
	},

	handleForms () {
		const forms = this.iframe.getElementsByTagName('form');

		for (let i = 0; forms && forms !== null && i < forms.length; i++) {
			const thisForm = forms[i];
			const action = thisForm.getAttribute('action');

			if (action) {
				thisForm.setAttribute('target', '_blank');
			}
		}

		event.preventDefault();
	},

	setContent: function (content, options = {}, callback = null) {
		const finalContent = content || '<html><body></body></html>';
		const { allowClicks, refresh, amp } = options;
		const set = function () {
			this.iframe.open();
			this.iframe.write(getDocType(finalContent));
			// Do not parse amp and sanitize, just serve it up in user-content
			this.iframe.write(amp ? finalContent : sanitizeHtml(finalContent, options));
			this.handleForms();
			this.iframe.close();
			this.iframe.removeEventListener('click', this.ignoreClicks);
			this.iframe.removeEventListener('click', this.handleClicks);

			if (allowClicks) {
				this.iframe.addEventListener('click', this.handleClicks);
			} else {
				this.iframe.addEventListener('click', this.ignoreClicks);
			}

			if (callback && typeof callback === 'function') {
				callback();
			}
		}.bind(this);

		if (refresh) {
			this.refresh().then(function () {
				set();
			});
		} else {
			set();
		}
	}
};

const child = new Child(() => {
	child.setHandler(new SecurePreview());
});
